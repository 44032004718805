<template>
  <Card :class="{'verde small-padding': !isScreenLgWidth }" :with-header="false" :with-footer="false">
    <template v-slot:default>
      <div class="row">
        <div v-if="!item.hasNoQty()" class="col-9">
          <div class="row mb-1">
            <div class="col-12">
              <h3 v-bind:class="{'verde': !isScreenLgWidth}" class="font-weight-semibold m-0"> {{ item.getProductName() }} - <span v-bind:class="{'text-warning':item.isPriceChanged()}"><Price :amount="item.wasUpdated() ? item.getPriceWithVat() : item.getInitialPriceWithVat()" :currency="item.getCurrency()" />&nbsp;/&nbsp;{{item.getUnit()}}</span></h3>
            </div>
          </div>
          <div v-if="item.getDesiredPriceWithVat()" class="row mb-1 text-warning">
            <div class="col-12">
              <span class="d-flex">{{$t('frontoffice.farmers.offers.stock-batch.desired_price')}}:<span class="ml-2"><Price :amount="item.getDesiredPriceWithVat()" :currency="item.getCurrency()" />&nbsp;/&nbsp;{{item.getUnit()}}</span></span>
            </div>
          </div>
          <div v-if="canShowMinMarketPriceInfoNote" class="row">
            <div class="col-12">
              <InfoNote>
                {{ $t('frontoffice.farmers.offers.stock-batch.min_market_price', {price: formatPrice(item.getMarketMinPriceWithVat(), item.getCurrency()), unit: item.getUnit()}) }}
              </InfoNote>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="d-flex">{{$t('frontoffice.farmers.offers.stock-batch.qty')}}: <span v-bind:class="{'text-warning':item.isQtyChanged()}"><Qty element-style="mr-0 ml-2 d-inline" :amount="item.wasUpdated() ? item.getQty() : item.getInitialQty()" :unit="item.getUnit()" />&nbsp;/&nbsp;{{$t('frontoffice.farmers.offers.stock-batch.weekly')}}</span></span>
            </div>
          </div>

        </div>
        <div v-else class="col-9 d-flex" :class="{'verde': !isScreenLgWidth}">
          <h3 v-bind:class="{'verde': !isScreenLgWidth}" class="text-danger font-weight-semibold mt-auto mb-auto">{{ item.getProductName() }} - {{$t('general.unavailable')}}</h3>
        </div>
        <div class="d-flex col-3">
          <Button class="align-self-center" :disabled="!editable" :type="updateButtonIcon" :name="updateButtonName" @click="updateItem(item)" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import {NumbersMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import InfoNote from "@/components/elements/InfoNote";

export default {
  name: "StockBatchItemCard",
  emits: ['update'],
  mixins: [NumbersMixin, ScreenSizeMixin],
  components: {InfoNote, Qty, Price, Card, Button},
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    canShowMinMarketPriceInfoNote() {
      // console.log(this.item.wasUpdated(), this.item.getPriceWithVat(), this.item.getInitialPriceWithVat(), this.item.getMarketMinPriceWithVat());
      return  this.item.getMarketMinPriceWithVat() > 0 && (this.item.wasUpdated() ? this.item.getPriceWithVat() : this.item.getInitialPriceWithVat()) > this.item.getMarketMinPriceWithVat()
    },
    updateButtonName: function () {
      let name = 'frontoffice.farmers.offers.stock-batch.buttons.update';
      if (this.item.hasNoQty()) {
        name = 'frontoffice.farmers.offers.stock-batch.buttons.available';
      }
      if (!this.isScreenLgWidth) {
        name = '';
      }

      return name;
    },
    updateButtonIcon: function () {
      if (this.isScreenLgWidth) {
        if (this.item.hasNoQty()) {
          return 'add_labeled';
        }

        return 'edit_labeled';
      }

      if (this.item.hasNoQty()) {
        return 'add';
      }

      return "edit";
    }
  },
  methods: {
    updateItem: function (item) {
      this.$emit('update', item);
    },
  }
}
</script>

<style scoped>
h3.verde {
  font-size: 1em;
}
span.d-flex.verde {
  font-size: 0.8em;
}
</style>
