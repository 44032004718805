<template>
  <Modal v-if="wasJustProcessed || stockBatch.isFinished()" :dismissible="false">
    <template v-slot:default>
      <h3 class="d-block text-center">{{$t('frontoffice.farmers.offers.dashboard.complete')}}.</h3>

      <div class="my-4 mx-auto row" style="max-width: 480px">
        <div class="col-12">
        <Button class="w-100" @click.stop="goToDashBoard" name="frontoffice.farmers.offers.en-gross.button.back_to_dashboard" type="confirm-mb-0" />
        </div>
      </div>
    </template>
  </Modal>
  <StockBatchItemModal v-if="selectedItem" :farmer="stockBatch.getFarm()" :editable="stockBatch.isEditable()" :item="selectedItem" @closemodal="selectedItem=null" @update="updateItem"/>
  <div class="verde-farmer-top with-sticky-footer-buttons" v-if="stockBatch">
    <Card class="card-header-pb-0 card-title-mb-0 card-msg-pt-0" :with-footer="false" :title="$t('frontoffice.farmers.offers.stock-batch.title',{farmer: stockBatch.getFarm().getName()})">
      <template v-slot:default>
        <span v-if="stockBatch.hasDesiredPrice()" class="">{{$t('frontoffice.farmers.offers.stock-batch.description_desired_price') }}</span>
        <span v-else-if="stockBatch.isInProgress()" class="">{{$t('frontoffice.farmers.offers.stock-batch.description') }}</span>
        <span class="d-block"><strong class="text-success">{{ interval }}</strong></span>
      </template>
    </Card>
    <div class="pb-2" v-if="!stockBatch.getAvailableItemCollection().isEmpty()">
      <div class="card p-2 text-center bg-verde">
        <div class="row text-center justify-content-center">
          <div class="col-sm-5 col-md-3 d-block align-self-center">
            <h2 class="mb-0 font-weight-semibold">{{ $t('frontoffice.farmers.offers.stock-batch.existing_products') }}</h2>
          </div>
        </div>
      </div>
      <div>
        <StockBatchItemCard :editable="stockBatch.isEditable()" v-for="(item) in stockBatch.getAvailableItemCollection().getItems()"
                       :key="item.getId()" :item="item" @update="showUpdateItemModal"/>
      </div>
    </div>
    <div class="pb-2" v-if="!stockBatch.getNotAvailableItemCollection().isEmpty()">
      <div class="card p-2 text-center bg-verde">
        <div class="row text-center justify-content-center">
          <div class="col-sm-5 col-md-3 d-block align-self-center">
            <h2 class="mb-0 font-weight-semibold">{{ $t('frontoffice.farmers.offers.stock-batch.not_existing_products') }}</h2>
          </div>
        </div>
      </div>
      <div>
        <StockBatchItemCard :editable="stockBatch.isEditable()" v-for="(item) in stockBatch.getNotAvailableItemCollection().getItems()"
                            :key="item.getId()" :item="item" @update="showUpdateItemModal"/>
      </div>
    </div>
    <StickyBottom v-if="!stockBatch.getAvailableItemCollection().isEmpty() || !stockBatch.getNotAvailableItemCollection().isEmpty()">
      <Button class="w-100" @click.stop="sendStockBatchToDone" :name="sendButtonName" type="confirm-mb-0" />
    </StickyBottom>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import {DatesMixin} from "@/mixins/GeneralMixin";
import StockBatchItemCard from "@/components/stock-batch/frontoffice/StockBatchItemCard";
import StockBatchItemModal from "@/components/stock-batch/frontoffice/StockBatchItemModal";
import Button from "@/components/elements/Button";
import StickyBottom from "@/components/elements/StickyBottom";
import StockBatch from "@/entities/StockBatch";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Modal from "@/components/elements/Modal.vue";

export default {
  name: "OfferStockBatch",
  emits: ['update'],
  mixins: [DatesMixin, LoadingModalMixin],
  components: {StickyBottom, Button, StockBatchItemModal, StockBatchItemCard, Card, Modal},
  props: {
    stockBatch: {
      type: Object,
    },
    offers: Array,
  },
  data: function () {
    return {
      selectedItem: null,
      wasJustProcessed: false,
    }
  },
  computed: {
    interval: function () {
      return this.formatDate(this.stockBatch.getStartAt()) + " - " + this.formatDate(this.stockBatch.getEndAt());
    },
    sendButtonName: function () {
      if (this.stockBatch.hasDesiredPrice()) {
        return 'frontoffice.farmers.offers.stock-batch.buttons.send_offer';
      }
      if (this.hasChanges) {
        return 'frontoffice.farmers.offers.stock-batch.buttons.confirm';
      }
      else return 'frontoffice.farmers.offers.stock-batch.buttons.confirm_no_changes';
    },
    hasChanges() {
      return this.stockBatch.getItemCollection().getItems().filter(item => item.isQtyChanged() || item.isPriceChanged()).length > 0;
    }
  },
  methods: {
    goToDashBoard: function () {
      this.$nextTick(function () {
        this.$router.push({name: 'frontoffice.breadcrumb.farmers.offers.dashboard', params:{'id': this.$route.params.id, 'batchId': this.$route.params.batchId}});
      });
    },
    showUpdateItemModal: function (item) {
      this.selectedItem = item;
    },
    sendStockBatchToDone: function () {
      let that = this;
      this.queueJob();
      this.axios.put(this.$store.state.config.getSendToDoneStockBatchUri(this.$route.params.batchId)).then(
          response => {
            let content = response.data;
            that.batch = new StockBatch(content.data);
            that.wasJustProcessed = true;
            that.$emit('update', that.batch);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    updateItem: function (item) {
      let that = this;
      this.selectedItem = null;
      let data = {
        qty: item.getQty(),
        price: item.getPriceWithVat(),
      };

      this.queueJob();
      this.axios.put(this.$store.state.config.getItemStockBatchUri(this.$route.params.batchId, item.getId()), data).then(
          response => {
            let content = response.data;
            that.batch = new StockBatch(content.data);
            that.$emit('update', true);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>

</style>
