<template>
  <Modal :name="item.getProductName()" :with-footer="true" :with-block-footer="!isScreenLgWidth" :dismissible="true">
    <template v-slot:default>
      <div class="row mb-2">
        <div class="col-12 col-lg-6">{{$t('frontoffice.farmers.offers.stock-batch.estimated_qty')}}</div>
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-center">
            <SelectInput class="col-8 d-inline" :class="{'error':v$.qty.$error}" :reference="item" :options="values" @input="updateQty"
                         :selected="qty"/>
            <span class="col-4 d-inline"> /{{ $t('frontoffice.farmers.offers.stock-batch.weekly') }}</span>
            </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.qty.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row" v-bind:class="{'mb-2':item.getDesiredPriceWithVat()}">
        <div class="col-12 col-lg-6">{{$t('frontoffice.farmers.offers.stock-batch.estimated_price', {currency: item.getCurrency()})}}</div>
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-center">
            <TextInput type="number" :class="{'error':v$.price.$error}" class="col-8 d-inline" :value="price"
                       :reference="item" @input="updatePrice"/>
            <span class="col-4 d-inline"> &nbsp;{{ item.getCurrency() }} / {{item.getUnit()}}</span>
          </div>
          <div class="col-12 input-errors" v-for="(error, index) of v$.price.$errors" :key="index">
            <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
          </div>
        </div>
      </div>
      <div v-if="item.getDesiredPriceWithVat()" class="row">
        <div class="col-12 col-lg-6">{{$t('frontoffice.farmers.offers.stock-batch.desired_client_price')}} <Price v-show="!isScreenLgWidth" :amount="item.getDesiredPriceWithVat()" :currency="item.getCurrency()" /></div>
        <div v-if="isScreenLgWidth" class="col-lg-6"><Price :amount="item.getDesiredPriceWithVat()" :currency="item.getCurrency()" /></div>
      </div>

      <div class="mt-4" v-if="farmer.showCommissionDetails()">
        <InfoNote>
          {{ $t('frontoffice.farmers.offers.stock-batch.commission_info') }}
          <span class="d-block text-info-600" v-html="$t('frontoffice.farmers.offers.stock-batch.farm_price_info', {farm_price: formatPrice(price * 0.7, item.getCurrency()), unit: item.getUnit()})"></span>
        </InfoNote>
      </div>

    </template>
    <template v-slot:footer>
      <Button :disabled="!isFormReady" class="mb-2 mr-auto ml-auto mb-lg-0 ml-lg-0 w-100 w-lg-auto" type="confirm" name="frontoffice.farmers.offers.stock-batch.buttons.update" @click="update" />
      <Button class="mr-auto ml-auto mr-lg-0 w-100 w-lg-auto" type="delete" name="frontoffice.farmers.offers.stock-batch.buttons.remove_qty" @click="removeQty" />
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {NumbersMixin, ScreenSizeMixin, ValidationsMixin} from "@/mixins/GeneralMixin";
import SelectInput from "@/components/elements/SelectInput";
import TextInput from "@/components/elements/TextInput";

import useVuelidate from '@vuelidate/core'
import { required, decimal, minValue } from '@vuelidate/validators'
import Price from "@/components/elements/Price";
import InfoNote from "@/components/elements/InfoNote";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "StockBatchItemModal",
  emits: ['update'],
  mixins: [ScreenSizeMixin, ValidationsMixin, NumbersMixin],
  components: {Price, TextInput, SelectInput, Button, Modal, InfoNote},
  validations: {
    qty: {
      required,
    },
    price: {
      required,
      decimal,
      priceMinValue: minValue(0.01),
    }
  },
  props: {
    item: Object,
    farmer: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      qty: this.item.wasUpdated() ? this.item.getQty() : this.item.getInitialQty(),
      price: this.item.wasUpdated() ? this.item.getPriceWithVat() : this.item.getInitialPriceWithVat(),
    }
  },
  computed: {
    values: function () {
      let values = [];
      let i = 0;
      while (i <= 500) {
        values.push({
          val: i,
          txt: i + " " + this.item.getUnit(),
        })
        //|| (i <= 50 && i % 5 === 0) || (i <= 100 && i % 10 === 0)
        if (i < 10) {
          i++;
        }
        else if ( i < 50 ) {
          i += 5;
        }
        else if ( i < 100 ) {
          i += 10;
        }
        else {
          i += 50;
        }
      }

      return values;
    },
    isFormReady: function () {
      return !(
          this.v$.qty.$error ||
          this.v$.price.$error
      );
    },
  },
  methods: {
    validateInputs: function ()
    {
      this.v$.qty.$touch();
      this.v$.price.$touch();
    },
    updateQty: function (item, qty) {
      this.qty = qty;
      this.v$.qty.$touch();
    },
    updatePrice: function (item, price) {
      this.price = price;
      this.v$.price.$touch();
    },
    update: function () {
      this.validateInputs();

      if (!this.isFormReady) {
        return;
      }

      this.item.setPriceWithVat(parseFloat(this.price));
      this.item.setQty(parseFloat(this.qty));
      this.$emit("update", this.item);
    },
    removeQty: function () {
      this.item.setQty(0);
      this.item.setPriceWithVat(parseFloat(this.item.getInitialPriceWithVat()));
      this.$emit("update", this.item);
    }
  }
}
</script>

<style scoped>
</style>
